import React from 'react';

import Layout from '../components/Layout';

import pic2 from '../assets/images/DAApic.png';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Double Aortic Arch</h2>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <p>
            Double aortic arch is an anomaly of the aortic arch in which two
            aortic arches form a complete vascular ring that can compress the
            trachea and/or esophagus. The two arches join to form the descending
            aorta which is usually on the left side (but may be right-sided or
            in the midline). In some cases the end of the smaller left aortic
            arch closes (left atretic arch) and the vascular tissue becomes a
            fibrous cord. Although in these cases a complete ring of two patent
            aortic arches is not present, the term ‘vascular ring’ is the
            accepted generic term even in these anomalies.
          </p>
        </div>
        <hr />
        <section className="spotlight">
          <div className="image">
            <img src={pic2} alt="" />
          </div>
          <div className="content">
            <h2>
              Double aortic arch is a relatively rare malformation.
              <br />
            </h2>
            <p>
              Most commonly there is a larger (dominant) right arch behind and a
              smaller (hypoplastic) left aortic arch in front of the
              trachea/esophagus.
            </p>
          </div>
        </section>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
